import { BASE_URL_API, HttpService } from '@carwash-project/modules/core';
import { Injectable, inject } from '@angular/core';
import { GetAllUsersModel, GetUserByIdModel, UpdateUserInfo } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly baseApi: string = inject(BASE_URL_API);
  private readonly httpService = inject(HttpService);
  private readonly pathUsers = `${this.baseApi}users`;

  public getAllUsers(
    query: GetAllUsersModel.Request
  ): Observable<GetAllUsersModel.Response> {
    return this.httpService.get(
      this.pathUsers,
      query,
      'Error al consultar el listado de usuarios'
    );
  }

  public getUserById(
    userId: string | number
  ): Observable<GetUserByIdModel.Response> {
    const url = `${this.pathUsers}/${userId}`;
    return this.httpService.get(
      url,
      null,
      'Error al consultar la información del usuario.'
    );
  }

  public getUserInfo(): Observable<GetUserByIdModel.Response> {
    const url = `${this.pathUsers}/me`;
    return this.httpService.get(
      url,
      null,
      'Error al consultar la información del usuario.'
    );
  }

  public updateUserInfo(
    body: UpdateUserInfo.Request,
    userId: string | number
  ): Observable<UpdateUserInfo.Response> {
    const url = `${this.pathUsers}/${userId}`;
    return this.httpService.patch(
      url,
      body,
      'Error al actualizar la información del usuario.'
    );
  }
}
