import { GetAllUsersModel, UpdateUserInfo } from '../models';

export namespace UserActions {
  export class List {
    static readonly type = '[User] Get all users';
    constructor(public query: GetAllUsersModel.Request) {}
  }

  export class Detail {
    static readonly type = '[User] Get User by Id';
    constructor(public userId: number | string) {}
  }

  export class DetailUserLogged {
    static readonly type = '[User] Get data User logged by token';
  }

  export class Update {
    static readonly type = '[User] Update user info';
    constructor(public body: UpdateUserInfo.Request) {}
  }

  export class UpdateUserByAdmin {
    static readonly type = '[User] Update user client by admin';
    constructor(
      public body: UpdateUserInfo.Request,
      public userID: number | string
    ) {}
  }

  export class Failure {
    static readonly type = '[User] Failure';
    constructor(public error: unknown) {}
  }
}
